import React from "react";
import unsplash from "../api/unsplash";
import ImageFrame from "./ImageFrame";
import ImagList from "./ImageList";

class CategoryContent extends React.Component {
  constructor(props) {
    super(props);
    this.category_types = props.category_types;
    this.fixHtmlHeight = props.fixHtmlHeight;
    this.getCurrentLang = props.getCurrentLang;
    this.cats = this.getCategoryType();
    props.loadPage(this.getCategoryType());
    this.state = { images: [], cats: this.getCategoryType() };
    this.__ = props.__;
    this.api = props.api;

  }
  getCategoryType() {
    const urlParams = window.location.pathname.split("/");
    return this.category_types[urlParams[2]];
  }

  onSearchSubmit = async (term, lang) => {
    /*/search/photos */
    var that = this;
    this.api.post(
      "digitalizehub/Playhub/games",
      { category: term,
        lang: lang },
      function (response) {
        if (response.message == "SUCCESS") {
            that.setState({ images: response.payload });
        }
      }
    );

    /*const response = await unsplash.get("/api/digitalizehub/Playhub/games", {
      params: {
        category: term,
        lang: lang,
      },
    });
    console.log(response.data.payload);
    //this.setState({ images: response.data.results });
    this.setState({ images: response.data.payload });*/
  };
  componentDidMount() {
    this.setState({ cats: this.cats });
    this.onSearchSubmit(this.cats, this.getCurrentLang());
  }
  componentWillReceiveProps() {
    if (this.getCategoryType() !== this.cats) {
      this.setState({
        cats: this.getCategoryType(),
      });
      this.onSearchSubmit(this.getCategoryType(), this.getCurrentLang());
    }
  }

  onImageSelect = (image) => {
    this.setState({ imagframe: image });
  };
  render() {
    return (
      <>
        <div className="ui container" style={{ marginTop: "10px" }}>
          <div className="ui row" style={{ marginTop: "10px" }}>
            <ImageFrame
              imagframe={this.state.imagframe}
              lang={this.getCurrentLang()}
            />
          </div>
          <div className="ui row" style={{ marginTop: "10px" }}>
            <h2>{this.__(this.getCategoryType().toUpperCase())}</h2>
            <ImagList
              images={this.state.images}
              onImageSelect={this.onImageSelect}
            />
          </div>
        </div>
      </>
    );
  }
}

export default CategoryContent;
