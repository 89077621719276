import React from 'react';

class ImageCard extends React.Component {
    constructor(props){
        super(props);
        this.state={spans:0};
        this.imageRef = React.createRef();
        console.log(props);
    }
    componentDidMount(){
        this.imageRef.current.addEventListener('load', this.setSpans);
    }
    setSpans = () => {
        const height = this.imageRef.current.clientHeight ;
        const spans = Math.ceil(height/10);
        this.setState({spans})
    }
    render() {
        const { description,icon } = this.props.image;
        return (
            <div style={{ gridRowEnd: `span ${this.state.spans}`  }}
                onClick={() => this.props.onImageSelect(this.props.image)} >
                <img ref={this.imageRef} 
                    alt={description}
                    src={icon} />

            </div>
        );
    }
}

export default ImageCard;