
let TEXTS = {
    'EN': {
        'HOME': 'Home',
        'ADVENTURE': 'Adventure',
        'ARCADE': 'Arcade',
        'CLASSICS': 'Classics',
        'GIRLS': 'Girls',
        'SPORTS': 'Sports',
        'STRATEGY': 'Strategy',
        'LATESTGAMES': 'Latest Games',
        'ACTIONGAMES': 'Action Games',
        'ADVENTUREGAMES': 'Adventure Games',
        'CLASSICSGAMES': 'Classics Games',
        'SPORTGAMES': 'Sports Games',
        'GIRLSGAMES': 'Girls Games',
        'STRATEGYGAMES': 'Strategy Games',
        'TERMS':'Terms & Conditions',
        'FAQ':'FAQ',
        'PRIVACY&POLICY':'Privacy & Policy',
        'CONTACT':'Contact',
        'COPYRIGHT':'Copyright ©2020 Playhub. All rights reserved.'
    },
    'AR': {
        'HOME': 'الرئيسية',
        'ADVENTURE': 'المغامرة',
        'ARCADE': 'الاكشن',
        'CLASSICS': 'الكلاسيكيات',
        'GIRLS': 'البنات',
        'SPORTS': 'الرياضة',
        'STRATEGY': 'الاستراتجية',
        'LATESTGAMES': 'أحدث الألعاب',
        'ACTIONGAMES': 'ألعاب الأكشن',
        'ADVENTUREGAMES': 'ألعاب المغامرات',
        'CLASSICSGAMES': 'ألعاب الكلاسكية',
        'SPORTGAMES': 'ألعاب الرياضية',
        'GIRLSGAMES': 'ألعاب البنات',
        'STRATEGYGAMES': 'ألعاب الإستراتجية',
        'TERMS':'الشروط والاحكام',
        'FAQ':'الاسئلة الشائعة',
        'CONTACT':'للتواصل',
        'COPYRIGHT':'حقوق النشر © 2020 Playhub. كل الحقوق محفوظة.'
    },
    'BA': {
        'HOME': 'Dom',
        'ADVENTURE': 'Avantura',
        'ARCADE': 'Arkada',
        'CLASSICS': 'Klasika',
        'GIRLS': 'Devojke',
        'SPORTS': 'Sportovi',
        'STRATEGY': 'Strategija',
        'LATESTGAMES': 'Najnovije igre',
        'ACTIONGAMES': 'Akcijske igre',
        'ADVENTUREGAMES': 'Avanturističke igre',
        'CLASSICSGAMES': 'Klasične igre',
        'SPORTGAMES': 'Sportske igre',
        'GIRLSGAMES': 'Igre za djevojke',
        'STRATEGYGAMES': 'Strateške igre',
        'TERMS':'Uvjeti i odredbe',
        'FAQ':'Često Postavljena Pitanja',
        'PRIVACY&POLICY':'Politika privatnosti',
        'CONTACT':'Kontakt',
        'COPYRIGHT':'Copyright © 2020 Playhub. Sva prava zadržana.'
    }
}

function get_text(lang_code, text_code, var_dict) {


    if (!(lang_code in TEXTS)) {
        lang_code = 'EN';
    }

    var t = TEXTS[lang_code][text_code];
    for (var k in var_dict) {
        if (t.indexOf("{" + k + "}") >= 0) {
            t = t.replace("{" + k + "}", var_dict[k]);
        }
    }

    return t;
}

export default get_text;