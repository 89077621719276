import React from "react";
import unsplash from "../api/unsplash";
import ImageFrame from "./ImageFrame";
import TestSwiper from "./SwiperNew";

class HomeContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {images: [], cats: ""}

    this.fixHtmlHeight = props.fixHtmlHeight;
    this.getCurrentLang = props.getCurrentLang;
    props.loadPage("home");
    this.api = props.api;

    this.__ = props.__;
  }
  onSearchSubmit = async (term) => {
    /*/search/photos */
    var that = this;

    this.api.post(
      "digitalizehub/Playhub/games",
      { lang: term },
      function (response) {
        if (response.message == "SUCCESS") {
            that.setState({ images: response.payload });
        }
      }
    );
   /*  const response = 
  
   await unsplash.get("/api/digitalizehub/Playhub/games", {
      params: {
        lang: term,
      },
    });
    console.log(response.data.payload);
    this.setState({ images: response.data.payload });*/
  };
  componentDidMount() {
    this.fixHtmlHeight();
    this.onSearchSubmit(this.getCurrentLang());
  }
  componentWillMount() {
    console.log("1");
  }
  onImageSelect = (image) => {
    this.setState({ imagframe: image });
  };
  render() {
    return (
      <>
        <div className="ui container">
          <div className="ui row" style={{ marginTop: "10px" }}>
            <ImageFrame
              imagframe={this.state.imagframe}
              lang={this.getCurrentLang()}
            />
          </div>
          <div className="ui row" style={{ marginTop: "10px" }}>
            <h2>{this.__("LATESTGAMES")}</h2>
            <TestSwiper
              images={this.state.images.filter(
                (images) => images.product_type === "New"
              )}
              onImageSelect={this.onImageSelect}
            />
          </div>
          <div className="ui row" style={{ marginTop: "10px" }}>
            <h2>{this.__("ACTIONGAMES")}</h2>
            <TestSwiper
              images={this.state.images.filter(
                (images) => images.category === "Arcade"
              )}
              onImageSelect={this.onImageSelect}
            />
          </div>
          <div className="ui row" style={{ marginTop: "10px" }}>
            <h2>{this.__("ADVENTUREGAMES")}</h2>
            <TestSwiper
              images={this.state.images.filter(
                (images) => images.category === "Adventure"
              )}
              onImageSelect={this.onImageSelect}
            />
          </div>
          <div className="ui row" style={{ marginTop: "10px" }}>
            <h2>{this.__("CLASSICSGAMES")}</h2>
            <TestSwiper
              images={this.state.images.filter(
                (images) => images.category === "Classics"
              )}
              onImageSelect={this.onImageSelect}
            />
          </div>
          <div className="ui row" style={{ marginTop: "10px" }}>
            <h2>{this.__("SPORTGAMES")}</h2>
            <TestSwiper
              images={this.state.images.filter(
                (images) => images.category === "Sports"
              )}
              onImageSelect={this.onImageSelect}
            />
          </div>
          <div className="ui row" style={{ marginTop: "10px" }}>
            <h2>{this.__("GIRLSGAMES")}</h2>
            <TestSwiper
              images={this.state.images.filter(
                (images) => images.category === "Girls"
              )}
              onImageSelect={this.onImageSelect}
            />
          </div>
          <div className="ui row" style={{ marginTop: "10px" }}>
            <h2>{this.__("STRATEGYGAMES")}</h2>
            <TestSwiper
              images={this.state.images.filter(
                (images) => images.category === "Strategy"
              )}
              onImageSelect={this.onImageSelect}
            />
          </div>
        </div>
      </>
    );
  }
}

export default HomeContent;
