import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import HomeContent from "./HomeContent";
import CategoryContent from "./CategoryContent";
import HeaderMenu from "./HeaderMenu";
import FooterMenu from "./FooterMenu";
import $ from "jquery";
import get_text from "../common/constants.js";
import PlayHubAPI from "../api/api";
window.$ = $;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name:"",
      currentPage: "Home",
      currentlang: "EN",
      loading: true,
      category_types: {
        adventure: "Adventure",
        arcade: "Arcade",
        strategy: "Strategy",
        classics: "Classics",
        girls: "GIRLS",
        sports: "SPORTS",
      },
      languages: [
        {
          code: "EN",
        },
        {
          code: "AR",
        },
        {
          code: "BA",
        },
      ],
      logo: "https://devreact.digitalizehub.com/assets/images/logo.png",
    };
    this.api = new PlayHubAPI();

    this.currentLanguage = null;
  }
  componentWillMount() {
    this.getCountryData();
  }
  getCountryData() {
    var that = this;

    let searchParams = new URLSearchParams(window.location.search);

    if (searchParams.get("token")) {
      that.getLanding(searchParams.get("token"));
    } else {
      that.getLanding();
    }
  }
  getLanding(he_token) {
    var that = this;
    this.api.post(
      "general/landing",
      { country_code: that.state.country_code, token: he_token },
      function (response) {
        if (response.status == "SUCCESS") {
          that.setState({
            languages: response.payload.providerop.available_languages,
            providerop: response.payload.providerop,
            available_countries:
              response.payload.providerop.available_countries,
            name: response.payload.providerop.name,
            loading: false,
          });
        }
      }
    );
  }

  loadPage(newPage) {
    this.setState({
      currentPage: newPage,
    });

    document.getElementById("main-wrapper").setAttribute("data-page", newPage);
  }
  loadLang(lang) {
    this.setState({
      currentlang: lang,
    });
  }
  fixHtmlHeight() {
    var screenHeight = window.screen.availHeight;
    document.body.style.width = "100%";
  }
  getCurrentProviderOperator() {
    return this.state.name;
  }
  getCurrentPage() {
    return this.state.currentPage;
  }
  getLanguages() {
    return this.state.languages;
  }

  __(text, var_dict = {}) {
    return get_text(this.currentLanguage, text, var_dict);
  }

  getCurrentLang() {
    var storageLang = localStorage.getItem("language");
    if (storageLang == "undefined") {
      storageLang = "EN";
      localStorage.setItem("language", storageLang);
    }

    var lang = storageLang ? storageLang : "EN";

    this.currentlang = lang;
    this.currentLanguage = lang;

    return lang;
  }

  getProfile() {
    return this.state.profile;
  }
  setLanguage(elm) {
    var langElm = $(elm.target);
    if (langElm.hasClass("far")) langElm = langElm.parent();

    var newLang = langElm.attr("data-lang-code");

    localStorage.setItem("language", newLang);
    {
      {
        document.location.reload();
      }
    }
  }
  getUserInfo() {
    return this.state.subscriber;
  }

  render() {
    return this.state.loading ? (
      <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column text-center">
        <main role="main" className="inner cover my-auto">
          <img src={"../assets/images/logo.png"} className="scale-up-center" />
        </main>
      </div>
    ) : (
      <div id="bigWrapper" data-lang={this.getCurrentLang()}>
        <BrowserRouter>
          <HeaderMenu
            __={this.__.bind(this)}
            getCurrentPage={this.getCurrentPage.bind(this)}
            getCurrentLang={this.getCurrentLang.bind(this)}
            getLanguages={this.getLanguages.bind(this)}
            logo={this.state.logo}
            getUserInfo={this.getUserInfo}
          />
          <Route
            exact
            path="/"
            render={(props) => (
              <HomeContent
                __={this.__.bind(this)}
                api={this.api}
                loadPage={this.loadPage.bind(this)}
                loadLang={this.loadLang.bind(this)}
                getCurrentPage={this.getCurrentPage.bind(this)}
                fixHtmlHeight={this.fixHtmlHeight.bind(this)}
                getCurrentLang={this.getCurrentLang.bind(this)}
              />
            )}
          />
          <Route
            path="/category/:cat"
            render={(props) => (
              <CategoryContent
                __={this.__.bind(this)}
                api={this.api}
                loadPage={this.loadPage.bind(this)}
                loadLang={this.loadLang.bind(this)}
                getCurrentPage={this.getCurrentPage.bind(this)}
                getCurrentLang={this.getCurrentLang.bind(this)}
                category_types={this.state.category_types}
              />
            )}
          />
          <FooterMenu
            __={this.__.bind(this)}
            getCurrentProviderOperator = {this.getCurrentProviderOperator.bind(this)}
            getCurrentPage={this.getCurrentPage.bind(this)}
            getCurrentLang={this.getCurrentLang.bind(this)}
            getLanguages={this.getLanguages.bind(this)}
          />
        </BrowserRouter>
      </div>
    );
  }
}
export default App;
