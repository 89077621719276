import React from "react";
// Import Swiper React components
import Swiper from "swiper";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import './ImageList.css';
// install Swiper components
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);


class TestSwiper extends React.Component{
  constructor(props){
    super(props);
    this.state={spans:0};
    this.imageRef = React.createRef();
    console.log(props);
}
  componentDidMount(){
    this.swiper = new Swiper(".swiper-container", {
        grabCursor: true, // little hand cursor over slides
        loop: true, // allows the slides to loop from the last slide back to the first 
        // in that direction
        spaceBetween:25,
        slidesPerView: 3, // allows the slide you're looking at to be the centered slide 
        // with the slide before and the slide after to be hanging just off the page 
        // from the left and right of it
        parallax: true, // Helps focus the users attention to the slide in front/center
        effect: "coverflow",
        navigation: {
          nextEl: ".swiper-button-next", // arrows on the side of the slides
          prevEl: ".swiper-button-prev", // arrows on the side of the slides
        },
        height: 30,
        updateonwindowresize : true,
        shouldswiperupdate:true,
        rebuildonupdate: true,
        updateOnImagesReady: true,
        observer: true
      });

}
render(){
  const swipersls = this.props.images.map(image=> {
    
    return (<img className="swiper-slide" 
          alt={image.description}
          src={image.icon}
          key={image.id}
          onClick={() => this.props.onImageSelect(image)}  />
    );
  });

return (
    <div className="swiper-container">
      <div className="swiper-wrapper">
        {swipersls}
      </div>
      <div className="swiper-pagination" />
      <div className="swiper-button-prev" />
      <div className="swiper-button-next" />
    </div>
  );

}
}
export default TestSwiper;
