import React from "react";

const ImageFrame = (props) => {
  console.log(props);
  if (!props.imagframe) {
    return <div></div>;
  }

  //const videoSrc = `https://www.youtube.com/embed/${props.imagframe.id}`;

    return (
      <div style={{ width:"100%"}}>
        <div className="ui segment">
          <h4 className="ui header" 
          style={props.lang == "AR" ? { direction: "rtl" , textAlign: "right" } : {}}
          >{props.imagframe.name}</h4>
          <iframe
            src={props.imagframe.redirect_link}
            title="description"
            frameBorder="0"
            scrolling="no"
          />
          <p 
          style={props.lang == "AR" ? { direction: "rtl" , textAlign: "right" } : {}}          
          >{props.imagframe.description}</p>
        </div>
      </div>
    );
  
};

export default ImageFrame;
