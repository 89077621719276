import React from "react";
import { Link } from "react-router-dom";
import SearchBar from "./SearchBar";
import "./ImageList.css";
import $ from "jquery";

class HeaderMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: props.currentPage,
      logo: props.logo,
      subscriber: props.subscriber,
    };

    this.getCurrentPage = props.getCurrentPage;
    this.getCurrentLang = props.getCurrentLang;
    this.getLanguages = props.getLanguages;
    this.getUserInfo = props.getUserInfo;
    this.__ = props.__;
  }
  componentDidMount() {
    $(".navbar-toggler").click(function () {
      $(this).toggleClass("is-active");
    });

    $(".navbar-collapse").on("shown.bs.collapse", function () {
      $(":not(.settingsNavLink,.nav-link,.navbar-collapse)").click(function (
        e
      ) {
        if ($(".navbar-collapse").hasClass("show")) {
          $(".navbar-toggler").removeClass("is-active");
          $(".navbar-collapse").collapse("hide");
        }
      });
    });
  }

  renderLogo() {
    return (
      <img
        src={this.state.logo}
        style={{ maxWidth: 200, minHeight: "35px" }}
        className="logoImage"
      />
    );
  }
  setLanguage(elm) {
    var langElm = $(elm.target);
    if (langElm.hasClass("far")) langElm = langElm.parent();

    var newLang = langElm.attr("data-lang-code");

    localStorage.setItem("language", newLang);
    {
      document.location.href = "/";
    }
  }

  renderLanguageSwitcher() {
    return (
      <div
        id="languageSwitcher"
        className="mt-1"
        data-sessionexists={this.getUserInfo() ? true : false}
      >
        {this.getLanguages().map((l) => {
          var activeClass = this.getCurrentLang() == l.code;

          if (activeClass) {
          } else {
            return (
              <a
                key={l.code}
                href="#"
                data-lang-code={l.code}
                onClick={this.setLanguage.bind(this)}
              >
                {l.code} <i className="fa fa-globe languageIcon"></i>
              </a>
            );
          }
        })}
      </div>
    );
  }

  render() {
    return (
      <header
        className="masthead mb-auto bg-black"
        data-lang={this.getCurrentLang()}
        style={this.getCurrentLang() == "AR" ? { direction: "rtl" } : {}}
      >
        {this.renderLanguageSwitcher()}
        <div className="inner ui container">
          <nav
            className="navbar nav nav-masthead navbar-expand-md navbar-light"
            style={{ textAlign: "right" }}
          >
            <Link to="/" activeclassname="active">
              {this.renderLogo()}
            </Link>

            <div
              className="hamburger js-hamburger navbar-toggler navbar-toggler-icon"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <div className="hamburger-box">
                <div className="hamburger-inner"></div>
              </div>
            </div>
            <div
              className="collapse navbar-collapse mt-3"
              id="navbarSupportedContent"
            >
              <li className="nav-item text-nowrap text-left">
                <Link
                  style={this.menuStyle}
                  className={
                    "nav-link " +
                    (this.getCurrentPage() == "home" ? "active " : "") +
                    (this.getCurrentLang() == "AR" ? "text-right " : "")
                  }
                  to="/"
                >
                  {this.__("HOME")}
                </Link>
              </li>
              <li className="nav-item text-nowrap text-left">
                <Link
                  style={this.menuStyle}
                  className={
                    "nav-link " +
                    (this.getCurrentPage() == "adventure" ? "active" : "") +
                    (this.getCurrentLang() == "AR" ? "text-right " : "")
                  }
                  to={"../category/adventure"}
                >
                  {this.__("ADVENTURE")}
                </Link>
              </li>
              <li className="nav-item text-nowrap text-left">
                <Link
                  style={this.menuStyle}
                  className={
                    "nav-link " +
                    (this.getCurrentPage() == "arcade" ? "active" : "") +
                    (this.getCurrentLang() == "AR" ? "text-right " : "")
                  }
                  to={"../category/arcade"}
                >
                  {this.__("ARCADE")}
                </Link>
              </li>
              <li className="nav-item text-nowrap text-left">
                <Link
                  style={this.menuStyle}
                  className={
                    "nav-link " +
                    (this.getCurrentPage() == "classics" ? "active" : "") +
                    (this.getCurrentLang() == "AR" ? "text-right " : "")
                  }
                  to={"../category/classics"}
                >
                  {this.__("CLASSICS")}
                </Link>
              </li>
              <li className="nav-item text-nowrap text-left">
                <Link
                  style={this.menuStyle}
                  className={
                    "nav-link " +
                    (this.getCurrentPage() == "girls" ? "active" : "") +
                    (this.getCurrentLang() == "AR" ? "text-right " : "")
                  }
                  to={"../category/girls"}
                >
                  {this.__("GIRLS")}
                </Link>
              </li>
              <li className="nav-item text-nowrap text-left">
                <Link
                  style={this.menuStyle}
                  className={
                    "nav-link " +
                    (this.getCurrentPage() == "sports" ? "active" : "") + 
                    (this.getCurrentLang() == "AR" ? "text-right " : "")
                  }
                  to={"../category/sports"}
                >
                  {this.__("SPORTS")}
                </Link>
              </li>
              <li className="nav-item text-nowrap text-left">
                <Link
                  style={this.menuStyle}
                  className={
                    "nav-link " +
                    (this.getCurrentPage() == "strategy" ? "active" : "") +
                    (this.getCurrentLang() == "AR" ? "text-right " : "")
                  }
                  to={"../category/strategy"}
                >
                  {this.__("STRATEGY")}
                </Link>
              </li>
            </div>
          </nav>
        </div>
      </header>
    );
  }
  renderUserWalletContainer() {
    return "<li></li>";
  }

  renderSubscriber() {
    if (this.getUserInfo()) {
      return (
        <React.Fragment>
          <li className="nav-item text-nowrap text-left">
            <a className="nav-link" style={this.menuStyle}>
              Unsubscribe
            </a>
          </li>
          <li className="nav-item text-nowrap text-left">
            <a
              className="nav-link"
              onClick={this.signOut}
              style={this.menuStyle}
            >
              {this.__("SIGNOUT")}
            </a>
          </li>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <li className="nav-item text-nowrap text-left">
            <Link
              style={this.menuStyle}
              className={
                "nav-link " +
                (this.getCurrentPage() == "signin" ? "active" : "")
              }
              to="signin"
            >
              {this.__("SIGNIN")}
            </Link>
          </li>
        </React.Fragment>
      );
    }
  }

  signOut() {
    this.api.post(
      "account/signout",
      {},
      function () {},
      function () {}
    );

    document.location.reload();
  }
}

export default HeaderMenu;
