import './ImageList.css';
import React from 'react';
import ImageCard from './ImageCard';

const ImageList = ({images, onImageSelect}) => {

    const imagesls = images.map(image=> {
        return <ImageCard key={image.id}
            image={image} 
            onImageSelect={onImageSelect}  />
    });
    return <div className="image-list">{imagesls}</div>;

}

export default ImageList;
